<template>
  <div class="section is-main-section">
    <form v-on:submit.prevent="save" class="column max">
      <b-field label="ID" v-if="application.id" horizontal>
          {{ application.id }}
      </b-field>

      <b-field label="Created at" v-if="application.created_at" horizontal>
        {{ application.created_at | moment('YYYY.MM.DD H:mm:ss') }}
      </b-field>

      <b-field label="Updated at" v-if="application.updated_at" horizontal>
        {{ application.updated_at | moment('YYYY.MM.DD H:mm:ss') }}
      </b-field>

      <b-field label="Type" horizontal>
        <span v-if="application.id" >{{ application.type }}</span>
        <b-select v-else placeholder="Select ..." v-model="application.type" required>
          <option value="Grafana">Grafana</option>
          <option value="Node-RED">Node-RED</option>
        </b-select>
      </b-field>

      <b-field label="Organization" v-if="!isNew" horizontal>
        <router-link :to="{ name: 'organization', params: { id: application.organization.id }}">
          {{ application.organization.name }}
        </router-link>
      </b-field>

      <b-field label="Organization" v-else horizontal>
        <OrganizationSelect v-model="application.organization_id" />
      </b-field>

      <b-field label="Name" horizontal>
        <b-input v-model="application.name" required type="text"></b-input>
      </b-field>

      <b-field label="Instance" horizontal v-if="isNew && (application.type === 'Grafana')">
        <b-select placeholder="Select ..." v-model="instance">
          <option value='shared'>Shared</option>
          <option value='own'>Own</option>
        </b-select>
      </b-field>

      <b-field label="URL" horizontal v-if="isNew && (application.type === 'Grafana')">
      <b-field grouped position="is-left">
        <p class="control" style="margin-top:10px">
        https://
        </p>
        <b-select placeholder="Select ..." v-model="application.domain">
            <option
                v-for="option of domainOprions"
                :value="option"
                :key="option">
                {{ option }}
            </option>
        </b-select>
        <b-input v-if="instance === 'own'" v-model="application.path" type="text" ></b-input>
      </b-field>
      </b-field>

      <b-field label="URL" v-if="!isNew" horizontal>
        {{ url }}
      </b-field>

      <b-field label="Note" horizontal>
        <b-input type="textarea" v-model="application.note" rows=2></b-input>
      </b-field>

      <b-field label="" horizontal >
        <div class="buttons">
          <b-button v-if="!application.id"
                    type="is-success"
                    icon-left="plus-box"
                    native-type="submit"
                    :disabled="isFetching"
          >Add Application</b-button>
          <b-button v-if="application.id"
                    type="is-success"
                    icon-left="content-save"
                    native-type="submit"
                    :disabled="isFetching"
          >Save Application</b-button>
          <b-button v-if="application.id"
                    type="is-danger"
                    v-on:click="showDeleteDialog"
                    icon-left="trash-can"
                    :disabled="isFetching"
          >Delete Application</b-button>
        </div>
      </b-field>
    </form>

    <div class="modal is-active" v-if="deleteDialog">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Delete confirmation</p>
          <button class="delete" aria-label="close" v-on:click="closeDeleteDialog"></button>
        </header>
        <section class="modal-card-body">
          Do you really want to delete this application?
        </section>
        <footer class="modal-card-foot">
          <button class="button is-danger" v-on:click="deleteMe">Delete</button>
          <button class="button" v-on:click="closeDeleteDialog">Cancel</button>
        </footer>
      </div>
    </div>

  </div>
</template>

<script>
import OrganizationSelect from '../components/OrganizationSelect.vue';

export default {
  name: 'application',
  components: { OrganizationSelect },
  data() {
    return {
      application: {
        organization_id: null,
        domain: 'grafana.hardwario.cloud',
      },
      deleteDialog: false,
      isFetching: false,
      instance: 'shared',
      domainOprions: ['grafana.hardwario.cloud'],
    };
  },
  created() {
    if (this.$route.params.id) {
      this.fetch();
    }
  },
  computed: {
    isNew() {
      return this.application.id === undefined;
    },
    url() {
      const queryString = this.application.orgId ? `?orgId=${this.application.orgId}` : '';
      return `https://${this.application.domain || ''}${this.application.path || ''}${queryString}`;
    },
  },
  methods: {
    async fetch() {
      this.isFetching = true;
      const { data } = await this.$http.get(`/v1/application/${this.$route.params.id}`);
      this.isFetching = false;
      this.application = data;
    },
    async save() {
      this.isFetching = true;
      if (this.application.id) {
        const { data } = await this.$http.put(`v1/application/${this.application.id}`, this.application);
        data.organization = this.application.organization;
        this.application = data;
      } else {
        const { data } = await this.$http.post('v1/applications', this.application);
        this.application = data;
        this.$router.push({ name: 'application', params: { id: data.id } });
      }
      this.isFetching = false;
      this.$toast.success('Application was saved');
    },
    async deleteMe() {
      if (!this.deleteDialog) return;
      await this.$http.delete(`/v1/application/${this.$route.params.id}`);
      this.closeDeleteDialog();
      this.$toast.success('Application was deleted');
      this.$router.push({ name: 'applications' });
    },
    showDeleteDialog(e) {
      e.preventDefault();
      this.deleteDialog = true;
    },
    closeDeleteDialog() {
      this.deleteDialog = null;
    },
  },
};
</script>
