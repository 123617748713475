<template>
  <section class="section is-main-section">
    <div class="table-container">
      <b-table
        :data="applications"
        :loading="loading"
        striped
        hoverable
        bordered
        paginated
        backend-pagination
        backend-filtering
        backend-sorting
        pagination-size="is-small"
        :total="total"
        :per-page="perPage"
        @page-change="onPageChange"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        @sort="onSort"
        @filters-change="filtersChange"
      >
        <b-table-column field="index" label="Index" v-slot="props">
          {{ sortOrder === 'asc' ? props.index + offset + 1 : total - props.index - offset }}
        </b-table-column>

        <b-table-column field="id" label="ID" sortable v-slot="props">
          {{ props.row.id }}
        </b-table-column>
        <b-table-column field="type" label="Type" sortable v-slot="props">
          {{ props.row.type }}
        </b-table-column>

        <b-table-column field="name" label="Name" sortable searchable>
          <template #searchable="props">
            <b-input v-model="props.filters[props.column.field]" size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.name }}
          </template>
        </b-table-column>

        <b-table-column field="type" label="Organization" sortable v-slot="props">
          <router-link :to="{ name: 'organization', params: { id: props.row.organization.id } }">
            {{ props.row.organization.name }}
          </router-link>
        </b-table-column>

        <b-table-column field="type" label="Url" sortable v-slot="props">
          <a :href="createUrl(props.row)" target="_blank">{{ createUrl(props.row) }}</a>
        </b-table-column>

        <b-table-column field="actions" label="Actions" v-if="isAdmin" v-slot="props">
          <router-link :to="{ name: 'application', params: { id: props.row.id } }">
            <b-button size="is-small">
              <b-icon icon="pencil" size="is-small" />
            </b-button>
          </router-link>
        </b-table-column>

        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>Nothing here.</p>
            </div>
          </section>
        </template>

        <template slot="bottom-left">
          <b-button
            tag="router-link"
            v-if="isAdmin"
            :to="{ name: 'application-add' }"
            type="is-success"
            icon-left="plus-box"
            >Add Application</b-button
          >

          <b-button v-on:click="fetch" type="is-info" icon-left="refresh" outlined style="margin-left: 100px"
            >Refresh data</b-button
          >
        </template>
      </b-table>
    </div>
  </section>
</template>

<script>
import { isEqual, pickBy, assign } from 'lodash';

export default {
  name: 'applications',
  data() {
    return {
      applications: [],
      total: 0,
      page: 1,
      perPage: 20,
      sortField: 'name',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      loading: false,
      offset: 0,
      params: {},
    };
  },
  created() {
    this.fetch();
  },
  computed: {
    isAdmin() {
      return this.$store.state.userIsAdmin;
    },
  },
  methods: {
    async fetch() {
      this.loading = true;
      const limit = this.perPage;
      const offset = (this.page - 1) * this.perPage;

      const params = assign(
        {
          sort_field: this.sortField,
          sort_order: this.sortOrder,
          limit,
          offset,
        },
        this.params
      );

      const res = await this.$http.get('/v1/applications', { params });

      this.applications = res.data;
      this.total = parseInt(res.headers['x-total'], 10);
      this.offset = offset;
      this.loading = false;
    },
    filtersChange(filters) {
      const params = pickBy(filters, (v) => v.length > 2);
      if (!isEqual(params, this.params)) {
        this.params = params;
        this.fetch();
      }
    },
    onPageChange(page) {
      this.page = page;
      this.fetch();
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.fetch();
    },
    createUrl(application) {
      const queryString = application.orgId ? `?orgId=${application.orgId}` : '';
      return `https://${application.domain || ''}${application.path || ''}${queryString}`;
    },
  },
};
</script>
